export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.hamburger, .hamburgerOpen': {
      div: {
        backgroundColor: 'white'
      }
    },
    '.container, .containerScrolled': {
      backgroundColor: 'black',
      borderTop: '4px solid',
      borderColor: 'primary',
      color: 'white'
    },
    '.navItem': {
      color: 'inherit',
      borderRight: 'solid 1px white',
      pr: '1rem',
      a: {
        textTransform: 'capitalize',
        letterSpacing: '2px',
        ':hover': {
          color: 'primary',
          textDecoration: 'underline'
        }
      }
    },
    svg: {
      color: 'white',
      width: '20px',
      height: '20px'
    },
    '.phoneContainer': {
      ml: 'auto',
      border: 'none',
      a: {
        p: '0.5rem',
        border: 'solid 1px white',
        borderRadius: '400px',
        ':hover': {
          backgroundColor: 'primary'
        }
      }
    },
    '.addressContainer': {
      a: {
        p: '0.5rem',
        border: 'solid 1px white',
        borderRadius: '400px',
        ':hover': {
          backgroundColor: 'primary'
        }
      }
    },

    '.logo': {
      left: '50%',
      transform: 'translateX(-50%)',
      overflow: 'hidden',
      transition: 'all ease-in-out 0.5s',
      maxHeight: '150px',
      img: {
        maxHeight: '150px'
      }
    },
    '.logoScrolled': {
      left: '50%',
      transition: 'all ease-in-out 0.5s',
      maxHeight: '0px',
      overflow: 'hidden',
      transform: 'translateX(-50%)'
    }
  },

  footer: {
    border: 'solid 2px',
    '.logo': {
      filter: 'brightness(0)'
    },
    mb: '80px'
  },

  ctaWidget: {
    display: ['flex', '', '', 'none'],
    a: {
      backgroundColor: 'primary'
      // p: '0rem'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
    fontWeight: 'normal',
    letterSpacing: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'capitalize',
    fontFamily: 'heading',
    color: 'inherit'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    letterSpacing: '4px',
    display: 'flex',
    textTransform: 'uppercase',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'inherit',
    fontFamily: 'body2',
    opacity: '0.8',
    fontWeight: '200'
  },
  text: {
    lineHeight: '1.75',
    mb: '2rem',
    p: {
      color: 'inherit',
      lineHeight: '1.75',
      fontSize: ['1rem', '1.1rem'],
      textAlign: 'center'
    }
  },

  sideBySide1: {
    backgroundColor: 'background',
    padding: ['1rem', '', '2rem', '3rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '75vh'],
      maxHeight: ['', '', '75vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title',
      borderBottom: 'solid 3px',
      borderColor: 'primary'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'primary'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        textAlign: 'left'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  centerBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    minHeight: '100vh',
    color: 'white',
    '::before': {
      background: 'linear-gradient(230deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 50%, rgba(0,0,0,1) 100%)',
      content: "''",
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    },

    '.section': {
      zIndex: '10',
      position: 'relative',
      textAlign: 'left',
      m: '2rem',
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'customVariants.title',
      borderBottom: 'solid 3px'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },

    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: 'primary',
      ':hover': {
        backgroundColor: 'secondary',
        borderColor: 'secondary'
      }
    }
  },

  centerBlock2: {
    variant: 'customVariants.centerBlock',
    background: 'none',
    height: 'auto',
    color: 'black'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: '100vh',
    img: {
      filter: 'brightness(0.7)'
    },
    '.hero_content_container': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: '100%',
      maxWidth: 'none',
      textAlign: 'center',
      ml: '0rem',
      alignItems: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
      border: 'none',
      margin: '0rem 0rem 0rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageBoxes: {
    backgroundColor: 'primary',
    padding: '20vh 2rem',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/brick-wall-dark.png)',
    color: 'white',
    '.contentBoxes': {
      maxWidth: '1200px',
      m: 'auto'
    },
    '.box': {
      textAlign: 'center',
      alignItems: 'center',
      width: ['', '', 'calc(50% - 2rem)', 'calc(50% - 4rem)'],
      margin: ['2rem 1rem', '', '1rem', '2rem'],
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['2rem', '2.25rem', '2.75rem', '3rem']
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text',
        flexGrow: '1',
        m: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      },
      a: {
        order: '4',
        m: '1rem auto'
      },
      '.ctaLink': {
        variant: 'buttons.primary'
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock',
    '*': {
      textAlign: 'left !important'
    }
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },
  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },
  homepageHours: {
    padding: '10vh 2rem',
    backgroundImage: 'url(https://www.transparenttextures.com/patterns/grunge-wall.png)',
    backgroundColor: 'primary',
    color: 'white'
  },

  homepageShout: {
    backgroundColor: 'background',
    marginBottom: '3rem',
    padding: ['1rem', '', '2rem 1rem', '3rem 2rem 10rem'],
    // borderTop: '1px solid',
    // borderBottom: '1px solid',
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '3px solid',
      borderColor: 'black',
      fontFamily: 'subheading',
      width: ['', '', '80%'],
      color: 'primary',
      backgroundColor: 'transparent',
      letterSpacing: '0px'
    },
    '.text': {
      fontFamily: 'body',
      fontSize: ['1.75rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['#f3f3ea'],
      color: 'text',
      textTransform: 'capitalize',
      padding: ['2rem', '2rem'],
      marginTop: '3rem',
      width: ['', '', '55%']
    },
    '.date': {
      backgroundColor: 'secondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'white',
      flexGrow: '1',
      fontWeight: 'bold',
      justifyContent: 'center',
      position: 'relative',
      left: ['', '', '', '-3rem'],
      bottom: ['', '', '', '-2rem']
    },
    '.imageContainer': {
      position: 'relative',
      top: ['', '', '', '5rem'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1'
    },

    margin: '0rem 0rem',
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    p: '15vh 2rem',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  menu: {
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '2.5rem'],
      textAlign: 'left',
      marginBottom: '1.5rem'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      marginBottom: '1.5rem',
      maxWidth: ['100%', '75%'],
      p: '1rem 1rem 2rem',
      mb: '1rem',
      display: 'flex'
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          '.menuItemName': {
            variant: 'customVariants.title',
            letterSpacing: '0px',
            fontWeight: '300',
            color: 'primary',
            mb: '0rem',
            fontFamily: 'subheading',
            fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem']
            // width: ['60%', '75%'],
          },
          '.itemVariantsContainer': {
            display: 'flex',
            flexWrap: 'wrap'
          },
          '.variantsContainer': {
            display: 'flex',
            flexWrap: 'wrap',
            margin: '0.5rem 0rem'
            // width: ['33.3%'],
          },
          '.variantContainer': {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            fontFamily: 'body2',
            backgroundColor: '#efefefba',
            padding: '0.75rem',
            borderRadius: '10px'
          },
          '.menuItemPriceLabel': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            marginBottom: '0.25rem'
          },
          '.variantContainerFloatTopRight': {
            fontSize: ['1.25rem', '1.5rem', '2rem']
          },
          '.menuItemLabelOptions': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemPriceVariants': {
            fontSize: '0.7rem',
            maxWidth: '350px'
          },
          '.menuItemDescription': {
            width: '100%',

            opacity: '0.6',
            fontSize: '1rem',
            fontWeight: '300',
            lineHeight: '1.5'
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'body2',
        backgroundColor: '#efefefba',
        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '0.7rem',
        maxWidth: '350px'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px',
        filter: 'brightness(0)'
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField': {
      borderColor: 'white!important'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white!important',
      '::placeholder': {
        color: 'white!important'
      },
      '&:placeholder, :placeholder': {
        color: 'white!important'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'white'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'lightgrey'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? ====  Success page  ====
  // ? ========================

  successFeedback: {
    height: '100vh',
    backgroundColor: 'black',
    '*': {
      color: 'white !important'
    },

    '.title': {
      order: 1,
      mb: '2rem'
    },
    '.subtitle': {
      order: 2,
      color: 'red !important',
      fontFamily: 'body'
    },
    '.text': {
      order: 3,
      maxWidth: '460px'
    }
  }
}
